
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { Add, ResourcesLocation } from '@/types/theme'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
import { dayFormat, formatDuring } from '@/utils/formatDate'

@Component({
  components: { MapLocation, UploadFile }
})

export default class ThemeAdd extends Vue {
  private info: Add = {
    projectId: '',
    activityName: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    description: '',
    resourceList: [],
    resourcesLocationList: []
  }

  pickerOptionsEnd= {
    disabledDate: this.disabledDate
  }

  pickerOptionsStart={
    disabledDate: this.disabledDateStart
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    activityName: [
      { required: true, message: '请输入活动名称', trigger: ['blur', 'change'] }
    ],
    endDate: [
      { required: true, message: '请选择活动日期', trigger: ['blur', 'change'] }
    ],
    description: [
      { required: true, message: '请输入描述', trigger: ['blur', 'change'] }
    ],
    resourceList: [
      { required: true, message: '请上传图片', trigger: 'change' }
    ],
    resourcesLocationList: [
      { required: true, message: '请选择位置坐标', trigger: 'change' }
    ]
  }

  private submitShow = false
  private resourcesLocationList: ResourcesLocation[] | null = null
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.$route.params.id && this.getDetail()
  }

  disabledDate (time: {getTime: Function}) {
    if (this.info.startDate) {
      return time.getTime() < Date.now() - 8.64e7 || (new Date(this.info.startDate).getTime() - 8.64e7 > time.getTime())
    } else {
      return true
    }
  }

  disabledDateStart (time: {getTime: Function}) {
    return time.getTime() < Date.now() - 8.64e7
  }

  // 开始时间范围
  selectableRangeTime () {
    const time = new Date(dayFormat(Date.now())).getTime() === new Date(this.info.startDate).getTime()
      ? formatDuring(new Date().getTime() + 1000 * 60) + '- 23:59:59'
      : '00:00:00 - 23:59:59'
    return time
  }

  startTimeChange () {
    this.info.endTime = ''
  }

  startDate () {
    this.info.startTime = ''
    this.info.endTime = ''
    this.info.endDate = ''
  }

  getDetail () {
    this.$axios.get(this.$apis.theme.selectTopicActivitiesById, {
      id: this.$route.params.id
    }).then(res => {
      this.info = res
      this.resourcesLocationList = res.resourcesLocationList
      console.log(new Date(this.info.startDate).getTime(), Date.now())
    })
  }

  locationChange (list: ResourcesLocation[]) {
    this.info.resourcesLocationList = list
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.id ? this.$apis.theme.updateTopicActivities : this.$apis.theme.insertTopicActivities
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'themeList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
